$(document).ready(function(){

    // Tooltip
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    $('.btn_menu_mobile').on('click' , function(){
            $(this).toggleClass('active');
            $('.list').slideToggle(200);
            // $('.formphone_mobile').hide(); 
            $('.list').toggleClass('active');
    });


});